<template>
  <div class="white-bg frontend">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div
      v-if="!pageNotFound"
      class="page-content"
    >
      <header>
        <b-container>
          <b-row class="auth-inner m-0">
            <router-link
              :to="'/appointments'"
              class="brand-logo"
            >
              <img :src="logoImg">
            </router-link>
          </b-row>
        </b-container>
      </header>

      <div class="breadcrumb-block breadcrumb__mobile">
        <b-container>
          <b-row>
            <b-col>
              <b-breadcrumb>
                <b-breadcrumb-item>
                  <router-link :to="'/appointments'">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.1003 5.29305L7.85033 1.21435C7.61699 1.03955 7.32533 1.03955 7.15033 1.21435L1.90033 5.29305C1.72533 5.40959 1.66699 5.58439 1.66699 5.75919V12.1686C1.66699 13.1591 2.42533 13.9166 3.41699 13.9166H11.5837C12.5753 13.9166 13.3337 13.1591 13.3337 12.1686V5.75919C13.3337 5.58439 13.2753 5.40959 13.1003 5.29305ZM8.66699 8.08987V12.7512H6.33366V8.08987H8.66699ZM11.5837 12.7512C11.9337 12.7512 12.167 12.5182 12.167 12.1686V6.05052L7.50033 2.43796L2.83366 6.05052V12.1686C2.83366 12.5182 3.06699 12.7512 3.41699 12.7512H5.16699V7.5072C5.16699 7.1576 5.40033 6.92453 5.75033 6.92453H9.25032C9.60032 6.92453 9.83366 7.1576 9.83366 7.5072V12.7512H11.5837Z"
                        fill="white"
                      />
                      <mask
                        id="mask0"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        x="1"
                        y="1"
                        width="13"
                        height="13"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.1003 5.29305L7.85033 1.21435C7.61699 1.03955 7.32533 1.03955 7.15033 1.21435L1.90033 5.29305C1.72533 5.40959 1.66699 5.58439 1.66699 5.75919V12.1686C1.66699 13.1591 2.42533 13.9166 3.41699 13.9166H11.5837C12.5753 13.9166 13.3337 13.1591 13.3337 12.1686V5.75919C13.3337 5.58439 13.2753 5.40959 13.1003 5.29305ZM8.66699 8.08987V12.7512H6.33366V8.08987H8.66699ZM11.5837 12.7512C11.9337 12.7512 12.167 12.5182 12.167 12.1686V6.05052L7.50033 2.43796L2.83366 6.05052V12.1686C2.83366 12.5182 3.06699 12.7512 3.41699 12.7512H5.16699V7.5072C5.16699 7.1576 5.40033 6.92453 5.75033 6.92453H9.25032C9.60032 6.92453 9.83366 7.1576 9.83366 7.5072V12.7512H11.5837Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0)">
                        <rect
                          x="0.5"
                          y="0.5"
                          width="14"
                          height="14"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </router-link>
                </b-breadcrumb-item>
                <b-breadcrumb-item>
                  <router-link :to="'/upcoming-events'">
                    Events
                  </router-link>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>
                  {{ event.name }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="events event_details main-content">
        <div class="page-shape event_shape" />
        <b-container>
          <b-row class="row8">
            <b-col
              lg="8"
              class="top-info-left"
            >
              <div
                v-if="event.imageURLs && event.imageURLs.length"
                class="featured-img"
              >
                <b-img :src="event.imageURLs ? (event.imageURLs.length ? event.imageURLs[0] : '') : ''" />
              </div>
            </b-col>

            <b-col
              lg="4"
              class="top-info-right"
            >
              <div class="event-info">
                <h3>{{ event.name }}<br>{{ event.nameSG }}</h3>
                <ul class="event_info">
                  <li class="event_date">
                    {{ event.dateLabel }}<br>{{ event.dateLabelSG }}
                  </li>
                  <li class="event_location">
                    {{ event.location }}<br>{{ event.locationSG }}
                  </li>
                </ul>

                <ul class="tags">
                  <li
                    v-for="(tag, key) in event.tags"
                    :key="key"
                    class="tag tag_1"
                  >
                    {{ tag }}
                  </li>
                </ul>
                <router-link
                  v-if="!packages.length"
                  :to="{ name: 'event-booking-frontend', params: { slug: event.slug || 0 }}"
                  class="btn btn-primary"
                >
                  Register for event 报名注册活动
                </router-link>
                <button
                  v-else
                  type="button"
                  class="btn btn-primary"
                  @click="scrollToPackage('packageDiv')"
                >
                  Register for event 报名注册活动
                </button>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <div class="event-description">
                <h1>{{ event.name }} {{ event.nameSG }}</h1>
                <p class="line-breaks-textarea">
                  {{ event.description }}
                </p>
              </div>
            </b-col>
          </b-row>

          <b-row
            v-if="packages.length"
            ref="packageDiv"
          >
            <b-col md="12">
              <div class="package-group">
                <h1>Package 配套细节</h1>

                <b-card
                  v-for="(pack, key) in packages"
                  :key="key"
                  :img-src="resolvePackageImageSrc(pack.customFields)"
                  img-alt=""
                  img-left
                  class="mb-3"
                >
                  <b-card-text>
                    <b-row>
                      <b-col md="8">
                        <h3>{{ pack.name }}</h3>
                        <p>{{ pack.name }}</p>
                      </b-col>
                      <b-col md="4">
                        <div class="price_info">
                          <h3>S$ {{ pack.price.toLocaleString() }}</h3>
                          <div class="status">
                            <span
                              :class="resolvePackageStatusClass(pack.status)"
                            >
                              {{ pack.status }}
                            </span>
                          </div>
                          <router-link
                            :disabled="pack.status != 'available' || pack.soldQuantity >= pack.availableQuantity"
                            :to="{ name: 'event-booking-frontend', params: { slug: event.slug || 0 }, query: { package: pack._id || 0 } }"
                            class="btn btn-primary"
                          >
                            Choose Package 选择配套
                          </router-link>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
            </b-col>
          </b-row>

        </b-container>
      </div>

      <footer>
        <b-container>
          <b-row>
            <b-col>
              <p>© {{ new Date().getFullYear() }} Nirvana Memorial Garden Pte. Ltd.</p>
            </b-col>
          </b-row>
        </b-container>
      </footer>
    </div>

    <not-found-error
      v-if="pageNotFound"
    />

  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BContainer, BCol, BBreadcrumb, BBreadcrumbItem, BImg, BCard, BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NotFoundError from '../pages/error/NotFoundError.vue'
import Loader from './Loader.vue'

export default {
  components: {
    BRow,
    BContainer,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BImg,
    BCard,
    BCardText,

    NotFoundError,
    Loader,
  },
  data() {
    return {
      event: {},
      packages: [],
      pageNotFound: false,
      pk1: require('@/assets/images/frontend/pk1.svg'),
      logoImg: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  beforeMount() {
    this.$http.get(`front-end/events/${this.$route.params.slug}/show`)
      .then(response => {
        this.event = response.data.event || {}
        this.packages = response.data.packages || []

        this.pageNotFound = false
      })
      .catch(error => {
        this.pageNotFound = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  updated() {
    if (this.$route.query.booking && this.packages.length) {
      this.scrollToPackage('packageDiv')
    }
  },

  methods: {
    resolvePackageStatusClass(status) {
      let className = ''
      if (status === 'available') {
        className = 'text-capitalize available'
      } else if (status === 'sold-out') {
        className = 'text-capitalize sold_out'
      } else if (status === 'waiting-list') {
        className = 'text-capitalize waiting_list'
      }
      return className
    },

    scrollToPackage(refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop - 135

      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    },

    resolvePackageImageSrc(fields) {
      let imgSrc = ''
      const obj = fields.find(o => o.type === 'file')

      if (obj) {
        imgSrc = obj.file
      }

      return imgSrc
    },
  },
}
</script>

<style>

</style>
